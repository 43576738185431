<template>
  <v-row justify="start">
    <v-dialog v-model="dialogPsycho" persistent scrollable max-width="500px">
      <v-card class="radius-card purple" v-if="psycho">
        <v-toolbar flat color="purple">
          <span class="headline white--text"
            >{{ filter.city }} - {{ filter.fields }}</span
          >
          <v-spacer></v-spacer>
          <v-btn icon color="white" @click="$emit('false', 3)"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="pa-2">
          <v-container class="white radius-card" v-if="psycho.data.length">
            <v-card
              v-for="psycho in psycho.data"
              :key="psycho.id"
              class="radius-card pa-2 mb-3 pointer"
              dark
              @click="toView(psycho)"
            >
              <div class="d-flex align-center">
                <v-avatar size="70" class="mr-3">
                  <img
                    :src="
                      psycho.photo_profile
                        ? `${env}/upload/photo_profile/${psycho.id}/${psycho.photo_profile}`
                        : dummy
                    "
                    class="img-fit"
                  />
                </v-avatar>
                <h2>{{ psycho.nama_lengkap }}</h2>
              </div>
            </v-card>
          </v-container>

          <v-container class="white radius-card" v-else>
            <v-card
              class="radius-card pa-5 mb-3 d-flex flex-column align-center"
              flat
            >
              <img src="../../../assets/img/404.svg" height="100px" alt="no" />
              <h3>0</h3>
            </v-card>
          </v-container>
        </v-card-text>
        <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "popUpPsycho",

  props: ["dialogPsycho", "psycho", "filter"],
  computed: {
    ...mapState({
      env: state => state.API_URL
    })
  },
  data() {
    return {};
  },
  methods: {
    toView(item) {
      this.$emit("false", 3);
      this.$router.push(`/pam/${item.id}`);
    }
  }
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
  background: #2196f3;
  transition: 0.5s;
}
.pointer:hover {
  transition: 0.5s;
  background: #0e8bf2;
}
.scroll {
  scrollbar-width: thin;
}
</style>
