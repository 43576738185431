<template>
  <v-row justify="start">
    <v-dialog v-model="dialogCity" persistent scrollable max-width="600px">
      <v-card class="radius-card purple" v-if="city">
        <v-toolbar dense flat color="purple">
          <span class="headline white--text">{{ province }}</span>
          <v-spacer></v-spacer>
          <v-btn icon color="white" @click="$emit('false', 1)"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="pa-2">
          <v-container class="white radius-card" v-if="city.data.length">
            <v-row>
              <v-col
                cols="12"
                md="6"
                class="pa-1"
                sm="6"
                v-for="city in city.data"
                :key="city.id"
              >
                <v-card
                  class="radius-card pa-5 ma-1 pointer"
                  dark
                  @click="$emit('fields', city)"
                >
                  <b>{{ city.nama_kota }}</b>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <v-container
            class="white radius-card d-flex flex-wrap justify-center"
            v-else
          >
            <v-card
              class="radius-card pa-5 mb-3 d-flex flex-column align-center"
              flat
            >
              <img src="../../../assets/img/404.svg" height="100px" alt="no" />
              <h3>No Data Found!</h3>
            </v-card>
          </v-container>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "popUpCity",

  props: ["dialogCity", "city", "province"],
  computed: {},
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
  background: #ab47bc;
  transition: 0.5s;
}
.pointer:hover {
  transition: 0.5s;
  /* background: #0e8bf2; */
  background: #9728ab;
}
.scroll {
  scrollbar-width: thin;
}
</style>
