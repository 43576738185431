<template>
  <v-row justify="start">
    <v-dialog v-model="dialogFields" persistent scrollable max-width="600px">
      <v-card class="radius-card purple" v-if="fields">
        <v-toolbar dense flat color="purple">
          <span class="headline white--text">Pilih Bidang</span>
          <v-spacer></v-spacer>
          <v-btn icon color="white" @click="$emit('false', 2)"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="pa-2">
          <v-container class="white radius-card">
            <v-card
              v-for="field in fields.data"
              :key="field.id"
              class="radius-card pointer"
              dark
              :color="
                field.nama == 'Psikologi Klinis'
                  ? 'a__'
                  : field.nama == 'Psikologi Pendidikan'
                  ? 'b__'
                  : 'c__'
              "
              @click="$emit('psycho', field)"
            >
              <h3>{{ field.nama }}</h3>
            </v-card>
          </v-container>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "popUpField",

  props: ["dialogFields", "fields"],
  computed: {},
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
  padding: 20px;
  margin-bottom: 10px;
  transition: 0.5s;
}
.pointer:hover {
  transition: 0.5s;
}
.scroll {
  scrollbar-width: thin;
}
</style>
